var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loadingMailing
        ? _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "span",
                      { staticClass: "text-h6" },
                      [
                        _vm._v(
                          _vm._s(_vm.getCampaignName()) +
                            " - " +
                            _vm._s(_vm.getMailingName()) +
                            "\n          "
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "mb-1",
                                            attrs: {
                                              color: _vm.$_dashMixin_getMailingIconColor(
                                                {
                                                  status: _vm.getMailingStatus()
                                                }
                                              )
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$_dashMixin_getMailingIcon({
                                                  status: _vm.getMailingStatus(),
                                                  discriminator: _vm.getIsResend()
                                                    ? "Resend"
                                                    : "MailingItem"
                                                })
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              850996208
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$_splitMixin_mailingItemStatusSelect.find(
                                    function(x) {
                                      return x.value === _vm.getMailingStatus()
                                    }
                                  ).text
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3" } },
                    [
                      _vm.$_splitMixin_isSplitTest
                        ? _c("LabelValue", {
                            attrs: {
                              label: "Winning Criteria",
                              value:
                                _vm.$_splitMixin_winningCriteriaSelect.find(
                                  function(x) {
                                    return x.value === _vm.getWinningCriteria()
                                  }
                                ).text +
                                " (" +
                                _vm.getWinningDistribution() +
                                "%)"
                            }
                          })
                        : _vm._e(),
                      _vm.getIsResend()
                        ? _c("LabelValue", {
                            attrs: {
                              label: "Resend Type",
                              value:
                                "" +
                                _vm.$_splitMixin_resendTypeSelect.find(function(
                                  x
                                ) {
                                  return x.value === _vm.getResendType()
                                }).text
                            }
                          })
                        : _vm._e(),
                      _vm.getScheduleDateLocal !== null
                        ? _c("LabelValue", {
                            attrs: {
                              label: "Schedule Date",
                              value: _vm._f("displayDateTimeFormat")(
                                _vm.getScheduleDateLocal
                              )
                            }
                          })
                        : _vm._e(),
                      _vm.$_splitMixin_isSplitTest &&
                      _vm.getScheduleDateLocal !== null
                        ? _c("LabelValue", {
                            attrs: {
                              label: "Winning Schedule Date",
                              value: _vm._f("displayDateTimeFormat")(
                                _vm.getWinningScheduleDateLocal
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider", {
                    staticClass: "mx-4",
                    attrs: { vertical: "" }
                  }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "8" } },
                    [
                      !_vm.loadingContacts
                        ? _c(
                            "div",
                            [
                              _c("span", { staticClass: "text-h6" }, [
                                _vm._v("Test Contacts")
                              ]),
                              _c("v-simple-table", {
                                attrs: {
                                  dense: "",
                                  "fixed-header": "",
                                  height: "300px"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                    Email\n                  "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                    Name\n                  "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                    Title\n                  "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                    Receive Test\n                  "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                    Receive Final Broadcast\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm.getTargetId() !== null &&
                                              _vm.getPersonalizations().length >
                                                0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.getPersonalizations(),
                                                      function(pers) {
                                                        return _c(
                                                          "th",
                                                          {
                                                            key: pers.name,
                                                            staticClass:
                                                              "text-left"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  pers.name
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e()
                                            ])
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(_vm.getContacts(), function(
                                              contact
                                            ) {
                                              return _c(
                                                "tr",
                                                { key: contact.email },
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(contact.email)
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(contact.name))
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(contact.mddo))
                                                  ]),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          readonly: "",
                                                          color: "success"
                                                        },
                                                        model: {
                                                          value:
                                                            contact.receiveTest,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              contact,
                                                              "receiveTest",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.receiveTest"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          readonly: "",
                                                          color: "success"
                                                        },
                                                        model: {
                                                          value:
                                                            contact.receiveFinalBroadcast,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              contact,
                                                              "receiveFinalBroadcast",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.receiveFinalBroadcast"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.getTargetId() !== null &&
                                                  _vm.getPersonalizations()
                                                    .length > 0
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.getPersonalizations(),
                                                          function(
                                                            pers,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "td",
                                                              {
                                                                key:
                                                                  contact.email +
                                                                  "-" +
                                                                  index
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      contact[
                                                                        pers
                                                                          .name
                                                                      ]
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  489888670
                                )
                              })
                            ],
                            1
                          )
                        : _c("v-skeleton-loader", {
                            attrs: { type: "article" }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-4" }),
              _c(
                "v-tabs",
                {
                  attrs: {
                    color: _vm.$_splitMixin_splitColors[_vm.splitIndex],
                    "fixed-tabs": ""
                  },
                  model: {
                    value: _vm.splitIndex,
                    callback: function($$v) {
                      _vm.splitIndex = $$v
                    },
                    expression: "splitIndex"
                  }
                },
                _vm._l(_vm.splitMixin_splits, function(split, index) {
                  return _c(
                    "v-tab",
                    {
                      key: "tab-" + index,
                      on: {
                        click: function($event) {
                          return _vm.tabClick(index)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title",
                          attrs: { color: _vm.$_splitMixin_splitColors[index] }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$_splitMixin_indexToChar(index))
                          ),
                          _vm.$_splitMixin_isSplit
                            ? _c("span", [
                                _vm._v(" (" + _vm._s(split.distribution) + "%)")
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.splitIndex,
                    callback: function($$v) {
                      _vm.splitIndex = $$v
                    },
                    expression: "splitIndex"
                  }
                },
                _vm._l(_vm.splitMixin_splits, function(split, index) {
                  return _c(
                    "v-tab-item",
                    { key: "content-" + index },
                    [
                      _c("SplitPreview", {
                        attrs: { id: split.id, split: split }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _c("v-skeleton-loader", { attrs: { type: "article@5" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }