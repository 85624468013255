<template>
  <div>
    <div v-if="!loadingMailing">
      <v-row>
        <v-col>
          <span class="text-h6">{{ getCampaignName() }} - {{ getMailingName() }}
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="$_dashMixin_getMailingIconColor({ status: getMailingStatus() })"
                  class="mb-1"
                  v-on="on"
                >
                  {{ $_dashMixin_getMailingIcon({ status: getMailingStatus(), discriminator: getIsResend() ? 'Resend' : 'MailingItem' }) }}
                </v-icon>
              </template>
              <span>{{ $_splitMixin_mailingItemStatusSelect.find(x => x.value === getMailingStatus()).text }}</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          lg="3"
        >
          <LabelValue
            v-if="$_splitMixin_isSplitTest"
            label="Winning Criteria"
            :value="`${$_splitMixin_winningCriteriaSelect.find(x => x.value === getWinningCriteria()).text} (${getWinningDistribution()}%)`"
          />
          <LabelValue
            v-if="getIsResend()"
            label="Resend Type"
            :value="`${$_splitMixin_resendTypeSelect.find(x => x.value === getResendType()).text}`"
          />
          <LabelValue
            v-if="getScheduleDateLocal !== null"
            label="Schedule Date"
            :value="getScheduleDateLocal | displayDateTimeFormat"
          />
          <LabelValue
            v-if="$_splitMixin_isSplitTest && getScheduleDateLocal !== null"
            label="Winning Schedule Date"
            :value="getWinningScheduleDateLocal | displayDateTimeFormat"
          />
        </v-col>
        <v-divider
          class="mx-4"
          vertical
        />
        <v-col
          cols="12"
          lg="8"
        >
          <div v-if="!loadingContacts">
            <span class="text-h6">Test Contacts</span>
            <v-simple-table
              dense
              fixed-header
              height="300px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Email
                    </th>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Title
                    </th>
                    <th class="text-left">
                      Receive Test
                    </th>
                    <th class="text-left">
                      Receive Final Broadcast
                    </th>
                    <div v-if="getTargetId() !== null && getPersonalizations().length > 0">
                      <th
                        v-for="pers in getPersonalizations()"
                        :key="pers.name"
                        class="text-left"
                      >
                        {{ pers.name }}
                      </th>
                    </div>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="contact in getContacts()"
                    :key="contact.email"
                  >
                    <td>{{ contact.email }}</td>
                    <td>{{ contact.name }}</td>
                    <td>{{ contact.mddo }}</td>
                    <td>
                      <v-checkbox
                        v-model="contact.receiveTest"
                        readonly
                        color="success"
                      />
                    </td>
                    <td>
                      <v-checkbox
                        v-model="contact.receiveFinalBroadcast"
                        readonly
                        color="success"
                      />
                    </td>
                    <div v-if="getTargetId() !== null && getPersonalizations().length > 0">
                      <td
                        v-for="(pers, index) in getPersonalizations()"
                        :key="`${contact.email}-${index}`"
                      >
                        {{ contact[pers.name] }}
                      </td>
                    </div>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <v-skeleton-loader
            v-else
            type="article"
          />
        </v-col>
      </v-row>
      <v-divider
        class="my-4"
      />
      <v-tabs
        v-model="splitIndex"
        :color="$_splitMixin_splitColors[splitIndex]"
        fixed-tabs
      >
        <v-tab
          v-for="(split, index) in splitMixin_splits"
          :key="`tab-${index}`"
          @click="tabClick(index)"
        >
          <span
            class="title"
            :color="$_splitMixin_splitColors[index]"
          >
            {{ $_splitMixin_indexToChar(index) }}<span v-if="$_splitMixin_isSplit"> ({{ split.distribution }}%)</span>
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="splitIndex">
        <v-tab-item
          v-for="(split, index) in splitMixin_splits"
          :key="`content-${index}`"
        >
          <SplitPreview
            :id="split.id"
            :split="split"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-skeleton-loader
      v-else
      type="article@5"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { splitMixin, splitMixinMailing, dashboardMixin } from '@/shared/mixins/mailing'

export default {
  name: 'MailingPreview',

  components: {
    LabelValue: () => import('@/components/custom/LabelValue'),
    SplitPreview: () => import('@/views/pages/components/mailing/SplitPreview')
  },

  mixins: [splitMixin, splitMixinMailing, dashboardMixin],

  props: {
    mailingItemId: {
      type: String,
      default: null,
      required: true
    },
    splitId: {
      type: String,
      default: null,
      required: false
    }
  },

  data () {
    return {
      loadingMailing: false,
      loadingContacts: false,
      splitIndex: 0,
      storeReference: 'mailingDialog'
    }
  },

  watch: {
    mailingItemId: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue) {
          this.loadMailing()
        } else {
          this.mailing = null
        }
      }
    },
    splitId: {
      immediate: true,
      handler (newValue, oldValue) {
        this.splitIndex = this.splitMixin_splits.findIndex(x => x.id === newValue)
      }
    }
  },

  computed: {
    ...mapGetters('mailingDialog', ['getWinningCriteria', 'getWinningDistribution', 'getMailingName', 'getIsResend', 'getResendType',
      'getContacts', 'getTargetId', 'getPersonalizations', 'getCampaignName', 'getScheduleDate', 'getWinningScheduleDate', 'getCampaignStatus',
      'getTargetAudienceStatus', 'getMailingStatus']),
    getScheduleDateLocal () {
      return this.$_dashMixin_dateToLocal(this.getScheduleDate())
    },
    getWinningScheduleDateLocal () {
      return this.$_dashMixin_dateToLocal(this.getWinningScheduleDate())
    }
  },

  created () {
    this.$_splitMixin_setStoreReference(this.storeReference)
  },

  methods: {
    async loadMailing () {
      this.loadingMailing = true
      await this.$store.dispatch(`${this.storeReference}/setMailing`, { mailingItemId: this.mailingItemId, noLoad: true })
      this.loadingMailing = false
      this.loadingContacts = true
      await this.$store.dispatch(`${this.storeReference}/loadContacts`, { mailingItemId: this.mailingItemId, receiveTest: true, receiveFinalBroadcast: true, noLoad: true })
      this.loadingContacts = false
    },
    tabClick (index) {
      this.splitIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
